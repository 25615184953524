import React, { useState } from "react";
import useUser from "lib/useUser";
import fetchJson, { FetchError } from 'lib/fetchJson';
import { client_system_uri } from 'config';
import Head from 'next/head';

export default function Login() {

  const [ errorMsg, setErrorMsg ] = useState( "" );
  const { user, mutateUser } = useUser( {
    redirectTo: "/mail",
    redirectIfFound: true
  } );

  // ユーザが見つからなかった時は作成
  if ( user ) {
    console.log( '[page] login' );
  } else {
    mutateUser(
      fetchJson( `${ client_system_uri }/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      } ), false
    );
    try {
    } catch ( error ) {
      if ( error instanceof FetchError ) {
        setErrorMsg( error.data.message );
      } else {
        console.error( "An unexpected error happened:", error );
      }
    }
  }
  let pageTitle = "ログイン:USOMメール";
  let pageDescription = "こちらからUSOMメールにログインが可能です。";
  return (
    <>

      <Head>
        <title>{ pageTitle }</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
        <meta name="description" content={ pageDescription }/>
      </Head>

      <p>{ errorMsg }</p>
      <p>LOGIN中</p>
    </>
  );
}
