/**
 * 設定を記載する
 *
 * server： 環境によるアクセス先を記載。 fetchがurlのみを対象としているため、urlが必要
 * mailDomains: メール作成に利用できる、設定ずみドメインの配列を記載。
 */

const dev = process.env.NODE_ENV !== 'production';
export const client_system_uri = dev ? 'http://localhost:3000' : 'https://www.kwonwe.com';
export const server_system_url = dev ? 'http://localhost:8105' : 'https://server.kwonwe.com';

export const client_system_domain = dev ? 'localhost:3000' : 'www.kwonwe.com';
export const server_system_domain = dev ? 'localhost:8105' : 'server.kwonwe.com';

export const MAIL_CHECK_ADDRESS = `${ server_system_url }/usom/check`;
export const MAIL_CREATE_ADDRESS = `${ server_system_url }/usom/create`;
export const MAIL_GET_ADDRESS = `${ server_system_url }/usom/mail/get/`;
export const MAIL_SEND_ADDRESS = `${ server_system_url }/usom/mail/send/`;


//　参考
// https://pretagteam.com/question/nextjs-error-only-absolute-urls-are-supported